import React from "react";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const NavbarB = () => {
  const navigate = useNavigate();

  const { user, logout } = UserAuth();
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("Logged out");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="w-full h-16 bg-blue-600 flex items-center px-10 py-2 justify-between">
      <h1 className="text-white text-3xl font-semibold font-Montserrat">
        Item B
      </h1>
      <Link
        to="/add-compDoc"
        className="w-36 bg-white text-blue-500 flex justify-center items-center font-semibold text-l h-10 rounded-lg"
      >
        Add Company Doc
      </Link>
      <Link
        to="/home"
        className="w-36 bg-white text-blue-500 flex justify-center items-center font-semibold text-l h-10 rounded-lg"
      >
        Certificate
      </Link>
      <button
        onClick={handleLogout}
        className="w-36 bg-white text-blue-500 font-semibold text-l h-10 rounded-lg"
      >
        Logout
      </button>
    </div>
  );
};

export default NavbarB;
