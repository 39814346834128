import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddCompDoc = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyIMONumber, setCompanyIMONumber] = useState("");
  const [vessels, setVessels] = useState("");
  const [certificateType, setCertificateType] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [certNum, setCertNum] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const navigate = useNavigate();
  const data = {
    companyName: companyName,
    companyIMONumber: companyIMONumber,
    vessels: vessels,
    certificateType: certificateType,
    certNum: certNum,
    issueDate: issueDate,
    expiryDate: expiryDate,
  };

  const submitForm = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://us-central1-qr-code-cert.cloudfunctions.net/app/api/createCompDoc",
        data
      )
      .then(navigate("/homeB"));
  };
  return (
    <div className="w-screen h-full flex flex-col justify-center items-center mt-16">
      <h2 className="text-2xl font-bold">ADD COMPANY DOC</h2>
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="companyName"
          >
            Company Name
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="companyName"
            type="text"
            placeholder="company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="imoNum"
          >
            IMO Number
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="text"
            placeholder="IMO num"
            value={companyIMONumber}
            onChange={(e) => setCompanyIMONumber(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="callSign"
          >
            Vessels
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="vessels"
            type="text"
            placeholder="vessels"
            value={vessels}
            onChange={(e) => setVessels(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="flag">
            Certificate Type
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="certificateType"
            type="text"
            placeholder="certificate type"
            value={certificateType}
            onChange={(e) => setCertificateType(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="flag">
            Certificate Number
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="certificateNum"
            type="text"
            placeholder="certificate number"
            value={certNum}
            onChange={(e) => setCertNum(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="issueDate"
          >
            Issue Date
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="issueDate"
            type="date"
            value={issueDate}
            onChange={(e) => setIssueDate(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="expiryDate"
          >
            Expiry Date
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="expiryDate"
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
        </div>

        <div class="flex items-center justify-between">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-auto"
            type="button"
            onClick={submitForm}
          >
            ADD Comp Doc
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCompDoc;
