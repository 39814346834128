import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Cert from "./pages/cert/Cert";
import CompDoc from "./pages/compdoc/CompDoc";
import Home from "./pages/Home";
import AddCompDoc from "./pages/compdoc/AddCompDoc";
import Navbar from "./pages/Navbar";
import NavbarB from "./pages/NavbarB";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Addcert from "./pages/cert/Addcert";
import EditCompDoc from "./pages/compdoc/EditCompDoc";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./pages/ProtectedRoute";
import Edit from "./pages/cert/Edit";
import HomeB from "./pages/HomeB";

function App() {
  return (
    <AuthContextProvider>
      <div className="App">
        <Routes>
          <Route
            path="/home"
            exact
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <Home />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/"
            exact
            element={
              <>
                <Signin />
              </>
            }
          />
          <Route path="/signup" exact element={<Signup />} />
          <Route
            path="/add-cert"
            exact
            element={
              <ProtectedRoute>
                <Addcert />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-compDoc"
            exact
            element={
              <ProtectedRoute>
                <AddCompDoc />
              </ProtectedRoute>
            }
          />
          <Route
            path="/homeB"
            exact
            element={
              <ProtectedRoute>
                <NavbarB />
                <HomeB />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-cert/:certId"
            exact
            element={
              <ProtectedRoute>
                <Edit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-comp/:compId"
            exact
            element={
              <ProtectedRoute>
                <EditCompDoc />
              </ProtectedRoute>
            }
          />

          <Route path="/get-cert/:certId" exact element={<Cert />} />
          <Route path="/get-compDoc/:compId" exact element={<CompDoc />} />
        </Routes>
      </div>
    </AuthContextProvider>
  );
}

export default App;
