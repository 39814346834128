import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddCert = () => {
  const [vesselName, setVesselName] = useState("");
  const [imoNum, setImoNum] = useState("");
  const [callSign, setCallSign] = useState("");
  const [flag, setFlag] = useState("");
  const [classNum, setClassNum] = useState("");
  const [certNum, setCertNum] = useState("");
  const [certIssued, setCertIssued] = useState("");
  const [certType, setCertType] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [remarks, setRemarks] = useState("");

  const navigate = useNavigate();
  const data = {
    vesselName: vesselName,
    imoNum: imoNum,
    callSign: callSign,
    flag: flag,
    classNum: classNum,
    certNum: certNum,
    certIssued: certIssued,
    certType: certType,
    issueDate: issueDate,
    expiryDate: expiryDate,
    remarks: remarks,
  };

  const submitForm = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://us-central1-qr-code-cert.cloudfunctions.net/app/api/create",
        data
      )
      .then(navigate("/home"));
  };
  return (
    <div className="w-screen h-full flex flex-col justify-center items-center mt-16">
      <h2 className="text-2xl font-bold">ADD CERT</h2>
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="vesselName"
          >
            Vessel Name
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="vesselName"
            type="text"
            placeholder="vessel name"
            value={vesselName}
            onChange={(e) => setVesselName(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="imoNum"
          >
            IMO Number
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="text"
            placeholder="IMO num"
            value={imoNum}
            onChange={(e) => setImoNum(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="callSign"
          >
            Call Sign
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="callSign"
            type="text"
            placeholder="call sign"
            value={callSign}
            onChange={(e) => setCallSign(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="flag">
            Flag
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="flag"
            type="text"
            placeholder="flag"
            value={flag}
            onChange={(e) => setFlag(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="classNum"
          >
            Class Number
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="classNum"
            type="text"
            placeholder="class num"
            value={classNum}
            onChange={(e) => setClassNum(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="certNum"
          >
            Certificate Number
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="certNum"
            type="text"
            placeholder="cert num"
            value={certNum}
            onChange={(e) => setCertNum(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="certIssued"
          >
            Certificates Issued
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="certIssued"
            type="text"
            placeholder="cert issued"
            value={certIssued}
            onChange={(e) => setCertIssued(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="certType"
          >
            Certificate Type
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="certType"
            type="text"
            placeholder="cert types"
            value={certType}
            onChange={(e) => setCertType(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="issueDate"
          >
            Issue Date
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="issueDate"
            type="date"
            value={issueDate}
            onChange={(e) => setIssueDate(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="expiryDate"
          >
            Expiry Date
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="expiryDate"
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="remarks"
          >
            Remarks
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="remarks"
            type="text"
            placeholder="remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </div>
        <div class="flex items-center justify-between">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-auto"
            type="button"
            onClick={submitForm}
          >
            ADD CERT
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCert;
