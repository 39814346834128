import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./Home.css";
import { Link, useNavigate } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import Dialog from "./Dialog";

const Home = () => {
  const [certs, setCerts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const rowIdRef = useRef();
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });
  const fetchCerts = async () => {
    try {
      let response = await axios.get(
        "https://us-central1-qr-code-cert.cloudfunctions.net/app/api/certDetails"
      );
      setCerts(response.data.data.reverse());
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const unsubscribe = collection(db, "certifications");
    onSnapshot(unsubscribe, (snapshot) => {
      setIsLoading(true);
      // snapshot.docs.forEach((doc) => setCerts((prev) => [...prev, doc.data]));
      setCerts(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    setIsLoading(false);
  }, []);

  const deleteCert = (id, vesselName) => {
    setDialog({
      message:
        "Are you sure you want to delete this vessel " + vesselName + "?",
      isLoading: true,
    });
    rowIdRef.current = id;
    // axios.delete(
    //   `https://us-central1-qr-code-cert.cloudfunctions.net/app/api/delete/${id}`
    // );
  };
  const areUSureDelete = (value) => {
    if (value) {
      axios.delete(
        `https://us-central1-qr-code-cert.cloudfunctions.net/app/api/delete/${rowIdRef.current}`
      );
      setDialog({
        message: "",
        isLoading: false,
      });
    } else {
      setDialog({
        message: "",
        isLoading: false,
      });
    }
  };

  return (
    <>
      <section class="container mx-auto p-6 font-mono">
        <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
          <div class="w-full overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                  <th class="px-4 py-3">Vessel Name</th>
                  <th class="px-4 py-3">IMO Number</th>
                  <th class="px-4 py-3">Call Sign</th>
                  <th class="px-4 py-3">Flag</th>
                  <th class="px-4 py-3">Class Number</th>
                  <th class="px-4 py-3">Certificate Number</th>
                  <th class="px-4 py-3">Issue Date</th>
                  <th class="px-4 py-3">Expiry Date</th>
                  <th class="px-4 py-3">Certifcate Type</th>
                  <th class="px-4 py-3">Certifcates Issued</th>
                  <th class="px-4 py-3">QR Code</th>
                  <th class="px-4 py-3">Remarks</th>
                  <th class="px-4 py-3">Actions</th>
                </tr>
              </thead>
              <tbody class="bg-white">
                {certs.map((data, index) => (
                  <tr class="text-gray-700" key={index}>
                    <td
                      data-label="Vessel name"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      {data.vesselName}
                    </td>
                    <td
                      data-label="IMO number"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      {data.imoNum}
                    </td>
                    <td
                      data-label="Call sign"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      {data.callSign}
                    </td>
                    <td
                      data-label="Flag"
                      class=" px-1 py-3 text-ms font-semibold border"
                    >
                      {data.flag}
                    </td>
                    <td
                      data-label="Class number"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      {data.classNum}
                    </td>
                    <td
                      data-label="Certificate number"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      {data.certNum}
                    </td>

                    <td
                      data-label="Issue date"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      {data.issueDate}
                    </td>
                    <td
                      data-label="Expiry date"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      {data.expiryDate}
                    </td>
                    <td
                      data-label="Expiry date"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      {data.certType}
                    </td>
                    <td
                      data-label="Certificates issued"
                      class="px-4 grid grid-cols-3 justify-center text-ms font-semibold overflow-scroll"
                    >
                      {data.certIssued}
                    </td>

                    <td
                      data-label="QR Code"
                      class="px-4 py-3 text-ms font-semibold border"
                    >
                      <img
                        class=" object-scale-down h-50 w-50 m-auto"
                        src={data.qrCode}
                        alt="QrCode"
                      />
                    </td>
                    <td
                      data-label="Remarks"
                      class=" px-1 py-3 text-ms font-semibold border"
                    >
                      {data.remarks}
                    </td>
                    <td className="text-sm flex flex-col items-center text-gray-900 font-bold px-6 py-4 gap-1 ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`/get-cert/${data.id}`}
                        className="bg-teal-600 text-white px-6 py-2 rounded-lg"
                      >
                        View
                      </a>

                      <Link
                        to={`/edit-cert/${data.id}`}
                        className="bg-blue-600 text-white px-6 py-2 rounded-lg"
                      >
                        Edit
                      </Link>
                      {dialog.isLoading && (
                        <Dialog
                          onDialog={areUSureDelete}
                          message={dialog.message}
                        />
                      )}
                      <Link
                        onClick={() => deleteCert(data.id, data.vesselName)}
                        to={"#"}
                        className="bg-red-600 text-white px-6 py-2 rounded-lg"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isLoading ? (
              console.log("Done Loading")
            ) : (
              <div
                role="status"
                className=" relative p-30 flex flex-row justify-center "
              >
                <svg
                  aria-hidden="true"
                  class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
