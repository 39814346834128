// import axios from "axios";
// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";

// const EditCompDoc = () => {
//   const [companyName, setCompanyName] = useState("");
//   const [companyIMONumber, setCompanyIMONumber] = useState("");
//   const [vessels, setVessels] = useState("");
//   const [certificateType, setCertificateType] = useState("");
//   const [issueDate, setIssueDate] = useState("");
//   const [certNum, setCertNum] = useState("");
//   const [expiryDate, setExpiryDate] = useState("");

//   const { compId } = useParams();
//   const navigate = useNavigate();

//   useEffect(() => {
//     console.log(compId);
//     const getCert = async () => {
//       try {
//         let response = await axios.get(
//           `https://us-central1-qr-code-cert.cloudfunctions.net/app/api/compDetail/${compId}`
//         );
//         setCompanyName(response.data.data.companyName);
//         setCompanyIMONumber(response.data.data.companyIMONumber);
//         setVessels(response.data.data.vessels);
//         setCertificateType(response.data.data.certificateType);
//         setCertNum(response.data.data.certNum);
//         setIssueDate(response.data.data.issueDate);
//         setExpiryDate(response.data.data.expiryDate);

//         console.log(response.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getCert();
//   }, []);

//   const data = {
//     companyName: companyName,
//     companyIMONumber: companyIMONumber,
//     certType: certificateType,
//     certNum: certNum,
//     vessels: vessels,
//     issueDate: issueDate,
//     expiryDate: expiryDate,
//   };

//   const updateForm = (e) => {
//     e.preventDefault();
//     axios
//       .put(
//         `https://us-central1-qr-code-cert.cloudfunctions.net/app/api/updateDoc/${compId}`,
//         data
//       )
//       .then(navigate("/homeB"));
//   };

//   return (
//     <div className="w-screen h-full flex flex-col justify-center items-center mt-16">
//       <h2 className="text-2xl font-bold">EDIT Comp doc</h2>
//       <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
//         <div class="mb-4">
//           <label
//             class="block text-gray-700 text-sm font-bold mb-2"
//             for="companyName"
//           >
//             Company Name
//           </label>
//           <input
//             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//             id="companyName"
//             type="text"
//             placeholder="Company Name"
//             value={companyName}
//             onChange={(e) => setCompanyName(e.target.value)}
//           />
//         </div>
//         <div class="mb-4">
//           <label
//             class="block text-gray-700 text-sm font-bold mb-2"
//             for="companyIMONumber"
//           >
//             Company IMO Number
//           </label>
//           <input
//             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//             id="companyIMONumber"
//             type="text"
//             placeholder="Company IMO Number"
//             value={companyIMONumber}
//             onChange={(e) => setCompanyIMONumber(e.target.value)}
//           />
//         </div>
//         <div class="mb-4">
//           <label
//             class="block text-gray-700 text-sm font-bold mb-2"
//             for="vessels"
//           >
//             Vessel
//           </label>
//           <input
//             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//             id="vessels"
//             type="text"
//             placeholder="Vessel"
//             value={vessels}
//             onChange={(e) => setVessels(e.target.value)}
//           />
//         </div>
//         <div class="mb-4">
//           <label
//             class="block text-gray-700 text-sm font-bold mb-2"
//             for="certType"
//           >
//             Certificate Type
//           </label>
//           <input
//             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//             id="certificateType"
//             type="text"
//             placeholder="cert type"
//             value={certificateType}
//             onChange={(e) => setCertificateType(e.target.value)}
//           />
//         </div>
//         <div class="mb-4">
//           <label
//             class="block text-gray-700 text-sm font-bold mb-2"
//             for="certNum"
//           >
//             Certificate Number
//           </label>
//           <input
//             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//             id="certNum"
//             type="text"
//             placeholder="cert number"
//             value={certNum}
//             onChange={(e) => setCertNum(e.target.value)}
//           />
//         </div>
//         <div class="mb-4">
//           <label
//             class="block text-gray-700 text-sm font-bold mb-2"
//             for="issueDate"
//           >
//             Issue Date
//           </label>
//           <input
//             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//             id="issueDate"
//             type="date"
//             value={issueDate}
//             onChange={(e) => setIssueDate(e.target.value)}
//           />
//         </div>
//         <div class="mb-4">
//           <label
//             class="block text-gray-700 text-sm font-bold mb-2"
//             for="expiryDate"
//           >
//             Expiry Date
//           </label>
//           <input
//             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//             id="expiryDate"
//             type="date"
//             value={expiryDate}
//             onChange={(e) => setExpiryDate(e.target.value)}
//           />
//         </div>

//         <div class="flex items-center justify-between">
//           <button
//             class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-auto"
//             type="button"
//             onClick={updateForm}
//           >
//             CONFIRM
//           </button>
//           <Link
//             to="/homeB"
//             class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-auto"
//             type="button"
//           >
//             CANCEL
//           </Link>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default EditCompDoc;
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const EditCompDoc = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyIMONumber, setCompanyIMONumber] = useState("");
  const [vessels, setVessels] = useState("");
  const [certificateType, setCertificateType] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [certNum, setCertNum] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const { compId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(compId);
    const getCert = async () => {
      try {
        let response = await axios.get(
          `https://us-central1-qr-code-cert.cloudfunctions.net/app/api/compDetail/${compId}`
        );
        setCompanyName(response.data.data.companyName);
        setCompanyIMONumber(response.data.data.companyIMONumber);
        setVessels(response.data.data.vessels);
        setCertificateType(response.data.data.certificateType);
        setCertNum(response.data.data.certNum);
        setIssueDate(response.data.data.issueDate);
        setExpiryDate(response.data.data.expiryDate);

        console.log(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCert();
  }, []);

  const data = {
    companyName: companyName,
    companyIMONumber: companyIMONumber,
    certificateType: certificateType,
    certNum: certNum,
    vessels: vessels,
    issueDate: issueDate,
    expiryDate: expiryDate,
  };

  const updateForm = (e) => {
    e.preventDefault();
    axios
      .put(
        `https://us-central1-qr-code-cert.cloudfunctions.net/app/api/updateDoc/${compId}`,
        data
      )
      .then(navigate("/homeB"));
  };

  return (
    <div className="w-screen h-full flex flex-col justify-center items-center mt-16">
      <h2 className="text-2xl font-bold">EDIT Comp Doc</h2>
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="companyName"
          >
            Company Name
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="companyName"
            type="text"
            placeholder="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="companyIMONumber"
          >
            IMO Number
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="companyIMONumber"
            type="text"
            placeholder="IMO num"
            value={companyIMONumber}
            onChange={(e) => setCompanyIMONumber(e.target.value)}
          />
        </div>

        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="vessels"
          >
            vessels
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="vessels"
            type="text"
            placeholder="vessels"
            value={vessels}
            onChange={(e) => setVessels(e.target.value)}
          />
        </div>

        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="certificateType"
          >
            Certificate Type
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="certificateType"
            type="text"
            placeholder="certificateType"
            value={certificateType}
            onChange={(e) => setCertificateType(e.target.value)}
          />
        </div>

        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="certNum"
          >
            Cert Number
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="certNum"
            type="text"
            placeholder="class num"
            value={certNum}
            onChange={(e) => setCertNum(e.target.value)}
          />
        </div>

        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="issueDate"
          >
            Issue Date
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="issueDate"
            type="date"
            value={issueDate}
            onChange={(e) => setIssueDate(e.target.value)}
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="expiryDate"
          >
            Expiry Date
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="expiryDate"
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
        </div>

        <div class="flex items-center justify-between">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-auto"
            type="button"
            onClick={updateForm}
          >
            CONFIRM
          </button>
          <Link
            to="/home"
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-auto"
            type="button"
          >
            CANCEL
          </Link>
        </div>
      </form>
    </div>
  );
};

export default EditCompDoc;
