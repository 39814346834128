import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import "../cert/Cert.css";
let Logo = require("../cert/jtm_logo.jpeg");
const CompDoc = () => {
  const [comp, setComp] = useState([]);
  const { compId } = useParams();

  useEffect(() => {
    console.log(compId);
    const getCert = async () => {
      try {
        let response = await axios.get(
          `https://us-central1-qr-code-cert.cloudfunctions.net/app/api/compDetail/${compId}`
        );
        setComp(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCert();
  }, []);

  return (
    <>
      <div id="verify-container" className="pt-10">
        <div
          style={{
            backgroundImage: "url(" + Logo + ")",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",

            paddingBottom: "175px",
            marginTop: "5px",
          }}
        ></div>

        <table style={{ marginTop: "50px" }}>
          <caption>
            <div>
              <h2>
                Verified Certificate
                <span
                  style={{
                    display: "inline-block",
                    position: "relative",
                    left: "5px",
                    top: "3px",
                  }}
                >
                  <AiFillCheckCircle />
                </span>
              </h2>
            </div>
          </caption>

          <thead>
            <tr>
              <th scope="col">Company Name</th>
              <th scope="col">IMO Num</th>
              <th scope="col">Vessels</th>
              <th scope="col">Certificate Type</th>
              <th scope="col">Certificate Number</th>
              <th scope="col">Issue Date</th>
              <th scope="col">Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            {comp && (
              <tr>
                <td data-label="Company name">{comp.companyName}</td>
                <td data-label="IMO number">{comp.companyIMONumber}</td>
                <td data-label="Vessels">{comp.vessels}</td>
                <td data-label="Cert Type">{comp.certificateType}</td>
                <td data-label="Cert Type">{comp.certNum}</td>
                <td data-label="Issue Date">{comp.issueDate}</td>
                <td data-label="Expiry date">{comp.expiryDate}</td>
              </tr>
            )}
          </tbody>
        </table>

        <h4
          style={{
            paddingTop: "20px",
            fontStyle: "italic",
            fontSize: "0.80rem",
          }}
        >
          For further verification purposes, please contact{" "}
          <a style={{ color: "blue" }} href="mailto:admin@jtmaritime.com">
            admin@jtmaritime.com
          </a>
        </h4>
      </div>
    </>
  );
};

export default CompDoc;
