// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAM3VdJqF25fDkk0J31U9YVxKerYsTvJ64",
  authDomain: "qr-code-cert.firebaseapp.com",
  databaseURL: "https://qr-code-cert-default-rtdb.firebaseio.com",
  projectId: "qr-code-cert",
  storageBucket: "qr-code-cert.appspot.com",
  messagingSenderId: "843408501574",
  appId: "1:843408501574:web:7c15ca5d63c1aa6a815347",
  measurementId: "G-7XT1761H6K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;

export const db = getFirestore(app);
